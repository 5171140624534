<template>
  <div>
    <div class="bg">
      <el-row>
        <el-col span="12">
          <div
            style="
              display: flex;
              justify-content: center;
              align-items: center;
              height: 600px;
            "
          >
            <img
              src="@/assets/bg.png"
              alt=""
              style="width: 100%; height: 100%"
            />
          </div>
        </el-col>
        <el-col span="12">
          <div
            style="
              height: 600px;
              backdrop-filter: blur(10px);
              background-color: rgb(255, 255, 255);
              padding: 30px 130px;
            "
          >
            <div>
              <h1>用户注册</h1>
            </div>
            <input
              type="text"
              placeholder="请输入邮箱"
              v-model="email"
              style="
                background-color: rgba(255, 255, 255, 0.3);
                border: 0px;
                border-bottom: 0.5px #666666 solid;
                height: 30px;
                width: 450px;
                font-size: 17px;
                font-weight: bold;
                margin-top: 20px;
              "
            />
            <!-- <input
              type="text"
              placeholder="请输入用户名"
              v-model="username"
              style="
                background-color: rgba(255, 255, 255, 0.3);
                border: 0px;
                border-bottom: 0.5px #666666 solid;
                height: 30px;
                width: 450px;
                font-size: 17px;
                font-weight: bold;
                margin-top: 40px;
              "
            /> -->
            <div style="display: flex; align-items: flex-end; width: 450px">
              <input
                type="text"
                placeholder="请输入验证码"
                v-model="captcha"
                style="
                  background-color: rgba(255, 255, 255, 0.3);
                  border: 0px;
                  border-bottom: 0.5px #666666 solid;
                  height: 30px;
                  width: 450px;
                  font-size: 17px;
                  font-weight: bold;
                  margin-top: 40px;
                "
              />
              <button
                :disabled="countdown > 0"
                @click="getMailCaptchas()"
                style="
                  background-color: #cb1414;
                  color: white;
                  border: 0px;
                  font-size: 15px;
                  width: 150px;
                  padding: 10px 10px;
                  cursor: pointer;
                "
              >
                {{ countdown > 0 ? `重新发送(${countdown}s)` : "获取验证码" }}
                <!-- 显示倒计时 -->
              </button>
            </div>
            <input
              type="password"
              placeholder="请输入密码"
              v-model="password"
              style="
                background-color: rgba(255, 255, 255, 0.3);
                border: 0px;
                border-bottom: 0.5px #666666 solid;
                height: 30px;
                width: 450px;
                font-size: 17px;
                font-weight: bold;
                margin-top: 40px;
              "
            />
            <div
              style="
                background-color: #cb1414;
                font-size: 30px;
                color: white;
                margin-top: 50px;
                text-align: center;
                padding: 10px;
                width: 450px;
              "
              @click="Registers()"
            >
              注册
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import { Register, getMailCaptcha } from "@/api/api";
export default {
  data() {
    return {
      username: "",
      password: "",
      email: "",
      mobile: "",
      captcha: "",
      countdown: 0, // 倒计时秒数
      timer: null, // 保存定时器
    };
  },
  methods: {
    Registers() {
      if (!this.password || !this.email || !this.captcha) {
        this.$message.error("请输入完整信息！");
      } else {
        const loading = this.$loading({
          lock: true,
          text: "Loading",
          spinner: "el-icon-loading",
          background: "rgba(255, 255, 255, 0.7)",
        });
        const username = this.email;
        Register(username, this.password, this.email, this.captcha).then(
          (res) => {
            if (res.data.code === 200) {
              loading.close();
              this.$message.success("注册成功！即将跳转到登录页面");
              setTimeout(() => {
                this.$router.push("/login");
              }, 1000);
            } else {
              this.$message.error("注册失败!" + res.data.msg);
              loading.close();
            }
          }
        );
      }
    },
    getMailCaptchas() {
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(255, 255, 255, 0.7)",
      });
      const event = "register";
      getMailCaptcha(this.email, event)
        .then((res) => {
          if (res.data.data === 200) {
            this.$message.success("验证码发送成功！");
            loading.close();
            this.countdown = 60;
            this.timer = setInterval(() => {
              if (this.countdown > 0) {
                this.countdown--;
              } else {
                clearInterval(this.timer); // 倒计时结束时清除定时器
              }
            }, 1000);
          } else {
            this.$message.error("验证码发送失败！" + res.data.msg);
            loading.close();
          }
        })
        .catch((res) => {
          this.$message.error("验证码发送失败！" + res.data.msg);
        });
    },
  },
  created() {},
  beforeDestroy() {
    // 销毁组件时清除定时器
    if (this.timer) {
      clearInterval(this.timer);
    }
  },
};
</script>

<style>
.bg {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
</style>
