import axios from "axios";

const axiosInstance = axios.create({
  baseURL: "https://api.jisuqipai.online/",
});

// 登录接口
export const Login = (account, password) => {
  return axiosInstance.post("/api/user/login", {
    account: account,
    password: password,
  });
};

// 注册接口
export const Register = (username, password, email, captcha) => {
  return axiosInstance.post("/api/user/register", {
    username: username,
    password: password,
    email: email,
    captcha: captcha,
  });
};

// usdt支付接口
export const usdtPay = (amount, token) => {
  return axiosInstance.post(
    "/api/payment/usdt",
    {
      amount: amount,
    },
    {
      headers: {
        token: token,
      },
    }
  );
};

// 获取文章列表
export const getArticles = () => {
  return axiosInstance.get("/api/index/articles");
};

// 获取对应id文章信息
export const getArticleDetails = (id) => {
  return axiosInstance.post("/api/index/articleDetails", {
    id: id,
  });
};

// 获取邮箱验证码
export const getMailCaptcha = (email, event) => {
  return axiosInstance.post("/api/ems/send", {
    email: email,
    event: event,
  });
};

// 通过邮箱找回验证码
export const resetpwd = (email, newpassword, captcha) => {
  return axiosInstance.post("/api/user/resetpwd", {
    email: email,
    newpassword: newpassword,
    captcha: captcha,
  });
};

// YonoGames上分体验
export const YonoUpscore = (uid, coin, token) => {
  return axiosInstance.post(
    "/api/demo/YonoUpscore",
    {
      uid: uid,
      coin: coin,
    },
    {
      headers: {
        token: token,
      },
    }
  );
};
