<template>
  <div>
    <!-- banner部分 -->
    <div class="cokeBanner">
      <el-row :gutter="20">
        <el-col :span="12" :offset="0" style="color: white; text-align: center">
          <h1 class="bannerTitle1">Cokejogo出海版</h1>
          <h1 class="bannerTitle2">深度二开优化</h1>
          <h1 class="bannerTitle2">出海接口游戏爆款产品</h1>
          <button class="createGame">+创建我的游戏</button>
        </el-col>
        <el-col :span="12" :offset="0"> &nbsp; </el-col>
      </el-row>
    </div>
    <!-- main -->
    <div class="main">
      <!-- 按钮部分 -->
      <el-row :gutter="20" class="playGame" style="margin-top: 10px">
        <el-col :xs="12" :lg="6" :offset="0">
          <a
            href="http://yono.h5.jisuqipai.online/"
            target="_blank"
            style="text-decoration: none"
          >
            <div class="cokeBtn">H5试玩</div>
          </a>
        </el-col>
        <el-col :xs="12" :lg="6" :offset="0">
          <div class="cokeBtn" @click="AndroidToast = true">
            下载Android客户端
          </div>
        </el-col>
        <el-col :xs="12" :lg="6" :offset="0">
          <div class="cokeBtn" @click="iosToast = true">下载iOS客户端</div>
        </el-col>
        <el-col :xs="12" :lg="6" :offset="0">
          <div class="cokeBtn" @click="openUpScore()">体验上分</div>
        </el-col>
      </el-row>
      <img
        src="@/assets/coke1.png"
        alt=""
        style="width: 100%; height: 550px; margin-top: 10px"
      />
      <img src="@/assets/coke3.jpg" alt="" style="width: 100%; height: 550px" />
      <img src="@/assets/coke4.png" alt="" style="width: 100%; height: 550px" />
      <img src="@/assets/coke5.png" alt="" style="width: 100%; height: 550px" />
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style>
.cokeBanner {
  background-image: url("@/assets/banner1.png");
  background-size: 115%;
  background-repeat: no-repeat;
  width: 100%;
  height: 350px;
  overflow: hidden;
}

.bannerTitle1 {
  font-size: 40px;
  margin: 5px;
  margin-top: 70px;
}

.bannerTitle2 {
  font-size: 30px;
  color: #7ec1ff;
  margin: 0;
  font-weight: normal;
}

.createGame {
  background-color: #4c55d8;
  font-size: 20px;
  color: white;
  border: 0px;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 20px;
}

.cokeBtn {
  font-size: 20px;
  font-weight: bolder;
  background-color: #4c55d8;
  color: white;
  padding: 20px 0;
  transition: all 0.2s ease;
  border-radius: 10px;
}

.cokeBtn:hover {
  color: black;
  background-color: white;
}

@media screen and (max-width: 768px) {
  .bannerTitle1 {
    margin-top: 30px;
    font-size: 20px;
  }
  .bannerTitle2 {
    font-size: 10px;
  }
  .createGame {
    font-size: 10px;
    padding: 5px 10px;
    margin-top: 10px;
  }
  .cokeBtn {
    font-size: 15px;
    padding: 5px;
    margin-top: 10px;
  }
  .cokeBanner {
    height: auto;
    padding-bottom: 20px;
  }
}
</style>
