<template>
  <div>
    <!-- banner -->
    <div class="agentBanner" style="padding-top: 40px">
      <h1 style="margin: 0; font-size: 50px">开始合作</h1>
      <h1 style="margin: 0; font-size: 40px; font-weight: normal">
        成为极速代理
      </h1>
      <h1
        style="
          margin: 0;
          font-size: 30px;
          margin-bottom: 30px;
          font-weight: normal;
        "
      >
        为海内外合法棋牌运营商提供线上平台服务
      </h1>
      <div class="btn" style="font-size: 25px; padding: 10px 30px">
        加入代理
      </div>
    </div>
    <div class="agentTitle">
      <h1 style="font-weight: normal">极速棋牌代理商合作条件</h1>
      <el-row :gutter="20" style="width: 100%; margin: 0px">
        <el-col :xs="12" :lg="5" class="partner"
          ><el-card
            ><div>
              <img
                src="@/assets/safe.png"
                alt=""
                style="width: 100px; height: 100px"
              />
              <p style="margin: 0">认可极速棋牌</p>
              <p style="margin: 0">产品和文化</p>
            </div>
          </el-card>
        </el-col>
        <el-col :xs="12" :lg="5" class="partner"
          ><el-card
            ><div>
              <img
                src="@/assets/customer.png"
                alt=""
                style="width: 100px; height: 100px"
              />
              <p style="margin: 0">追求为客户</p>
              <p style="margin: 0">创造价值为前提</p>
            </div></el-card
          ></el-col
        >
        <el-col :xs="12" :lg="5" class="partner"
          ><el-card
            ><div>
              <img
                src="@/assets/exp.png"
                alt=""
                style="width: 100px; height: 100px"
              />
              <p style="margin: 0">具有丰富的</p>
              <p style="margin: 0">互联网产品销售经验</p>
            </div></el-card
          ></el-col
        >
        <el-col :xs="12" :lg="5" class="partner"
          ><el-card
            ><div>
              <img
                src="@/assets/team.png"
                alt=""
                style="width: 100px; height: 100px"
              />
              <p style="margin: 0">拥有一定规模的</p>
              <p style="margin: 0">和有经验的销售团队</p>
            </div></el-card
          ></el-col
        >
        <el-col :xs="24" :lg="5" class="partner"
          ><el-card
            ><div>
              <img
                src="@/assets/deamon1.png"
                alt=""
                style="width: 100px; height: 100px"
              />
              <p style="margin: 0">具备一定优质的</p>
              <p style="margin: 0">客户和当地资源</p>
            </div></el-card
          ></el-col
        >
      </el-row>
      <h1 style="font-weight: normal; margin-top: 50px">
        极速棋牌代理级别与权益
      </h1>
      <el-row gutter="20" style="margin: 10px 0">
        <el-col :xs="12" :lg="6">
          <el-card>
            <img
              src="@/assets/Sliver.png"
              alt=""
              style="width: 120px; height: 100px"
            />
            <div>
              <p style="margin: 0">下级客户无门槛限制</p>
              <p style="margin: 0">享受20%返点</p>
              <p style="margin: 0">免费开通银牌合作伙伴权限</p>
              <div class="block">
                <p style="margin: 0">&nbsp;</p>
                <p style="margin: 0">&nbsp;</p>
              </div>
            </div>
            <button class="agentBtn" style="margin-top: 10px">
              注册成为代理
            </button>
          </el-card>
        </el-col>
        <el-col :xs="12" :lg="6">
          <el-card>
            <img
              src="@/assets/Gold.png"
              alt=""
              style="width: 120px; height: 100px"
            />
            <div>
              <p style="margin: 0">
                下级客户消费5000U以上可以免费升级为金牌合作伙伴
              </p>
              <p style="margin: 0">享受30%返点</p>
              <p style="margin: 0">或者支付500U直接升级成为金牌合作伙伴权限</p>
            </div>
            <button class="agentBtn" style="margin-top: 10px">
              注册成为代理/升级
            </button>
          </el-card>
        </el-col>
        <el-col :xs="12" :lg="6">
          <el-card>
            <img
              src="@/assets/platinum.png"
              alt=""
              style="width: 120px; height: 100px"
            />
            <div>
              <p style="margin: 0">
                下级客户消费20000U以上可以免费升级白金合作伙伴
              </p>
              <p style="margin: 0">享受40%返点</p>
              <p style="margin: 0">或者支付2000直接升级成为白金合作伙伴权限</p>
            </div>
            <button class="agentBtn" style="margin-top: 10px">
              注册成为代理/升级
            </button>
          </el-card>
        </el-col>
        <el-col :xs="12" :lg="6">
          <el-card>
            <img
              src="@/assets/deamon.png"
              alt=""
              style="width: 120px; height: 100px"
            />
            <div>
              <p style="margin: 0">
                下级客户消费50000U以上可以免费升级为钻石合作伙伴
              </p>
              <p style="margin: 0">享受50%返点</p>
              <p style="margin: 0">或者支付5000U直接升级成为钻石合作伙伴权限</p>
            </div>
            <button class="agentBtn" style="margin-top: 10px">
              注册成为代理/升级
            </button>
          </el-card>
        </el-col>
      </el-row>
      <!-- <h1 style="font-weight: normal; margin-top: 50px">
        极速棋牌代理月榜排名
      </h1>
      <div style="color: white; line-height: 35px">
        <el-row style="color: black; font-weight: bolder">
          <el-col :span="8">名次</el-col>
          <el-col :span="8">玩家昵称</el-col>
          <el-col :span="8">段位</el-col>
        </el-row>
        <el-row style="background-color: #ee6401">
          <el-col :span="8" :offset="0">
            <img
              src="@/assets/vip1.png"
              alt=""
              style="height: 20px; width: 20px; transform: translateX(-50px)"
            />第一名</el-col
          >
          <el-col :span="8" :offset="0">Legend、小雨</el-col>
          <el-col :span="8" :offset="0">钻石代理</el-col>
        </el-row>
        <el-row style="background-color: #3d98e1">
          <el-col :span="8" :offset="0">
            <img
              src="@/assets/vip1.png"
              alt=""
              style="height: 20px; width: 20px; transform: translateX(-50px)"
            />第二名</el-col
          >
          <el-col :span="8" :offset="0">LD、TAKUMI</el-col>
          <el-col :span="8" :offset="0">钻石代理</el-col>
        </el-row>
        <el-row style="background-color: #837e7b">
          <el-col :span="8" :offset="0">
            <img
              src="@/assets/vip1.png"
              alt=""
              style="height: 20px; width: 20px; transform: translateX(-50px)"
            />第三名</el-col
          >
          <el-col :span="8" :offset="0">YGI托比马歌尔</el-col>
          <el-col :span="8" :offset="0">钻石代理</el-col>
        </el-row>
        <el-row style="background-color: #ececec; color: black">
          <el-col :span="8" :offset="0">
            <img
              src="@/assets/vip1.png"
              alt=""
              style="height: 20px; width: 20px; transform: translateX(-50px)"
            />第四名</el-col
          >
          <el-col :span="8" :offset="0">飞哥精神</el-col>
          <el-col :span="8" :offset="0">黄金代理</el-col>
        </el-row>
        <el-row style="background-color: white; color: black">
          <el-col :span="8" :offset="0">
            <img
              src="@/assets/vip1.png"
              alt=""
              style="height: 20px; width: 20px; transform: translateX(-50px)"
            />第五名</el-col
          >
          <el-col :span="8" :offset="0">YG能猫叶星辰</el-col>
          <el-col :span="8" :offset="0">黄金代理</el-col>
        </el-row>
        <el-row style="background-color: #ececec; color: black">
          <el-col :span="8" :offset="0">
            <img
              src="@/assets/vip1.png"
              alt=""
              style="height: 20px; width: 20px; transform: translateX(-50px)"
            />第六名</el-col
          >
          <el-col :span="8" :offset="0">斗鱼TV、LHJ</el-col>
          <el-col :span="8" :offset="0">黄金代理</el-col>
        </el-row>
      </div> -->
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style>
.agentBanner {
  background-image: url("@/assets/banner5.jpg");
  height: 350px;
  background-size: cover;
  background-repeat: no-repeat;
  text-align: center;
  color: white;
}

.el-col-lg-5 {
  width: 20%;
}

.agentBtn {
  background-color: white;
  color: #cb1414;
  border: 1px #cb1414 solid;
  padding: 10px 20px;
  transition: all 0.2s ease;
}

.agentBtn:hover {
  color: white;
  background-color: #cb1414;
}

.agentTitle {
  text-align: center;
  margin: 0 150px;
}

@media screen and (max-width: 768px) {
  .agentTitle {
    margin: 0;
  }
  .partner {
    margin-bottom: 10px;
    width: 50%;
  }
  .partner > .el-col-xs-24 {
    width: 100% !important;
  }
  .block::before {
    content: "";
    display: block;
    height: 29px;
  }
  .el-card {
    margin-bottom: 10px;
  }
}

@media screen and (min-width: 1900px) {
  .block {
    display: none;
  }
}
</style>
