<template>
  <div>
    <div class="customTitle">
      <i class="fa fa-home"></i>
      <span style="margin-left: 10px">主页</span>
      <i class="fa fa-angle-right" style="margin-left: 10px"></i>
      <span style="margin-left: 10px">定制服务</span>
    </div>
    <!-- 定制服务部分 -->
    <div class="customService">
      <h3 style="margin: 0 0 0 10px">
        定制服务
        <span style="float: right; font-weight: 300; margin: 0 10px 0 0"
          >更多>></span
        >
      </h3>
      <div style="border-bottom: 1px solid #dcdfe6; margin: 10px 0 0 0" />
      <el-row :gutter="20">
        <el-col
          :xs="24"
          :lg="6"
          :offset="0"
          v-for="item in about"
          :key="item.id"
          style="padding: 20px"
        >
          <a
            :href="item.url"
            target="_blank"
            style="text-decoration: none; color: inherit; cursor: auto"
          >
            <el-row gutter="40">
              <el-col :xs="3" :lg="6">
                <img
                  :src="item.image"
                  alt="image"
                  style="width: 40px; height: 40px; border-radius: 100px"
                />
              </el-col>
              <el-col :xs="21" :lg="18" style="line-height: 15px">
                <p
                  style="
                    font-weight: bolder;
                    margin-top: 0px;
                    margin-bottom: 5px;
                  "
                >
                  {{ item.title }}
                  <i
                    class="fa fa-angle-right"
                    style="
                      float: right;
                      color: #abb0bd;
                      border: 1px #abb0bd solid;
                      padding: 0px 5px;
                      border-radius: 100px;
                    "
                  ></i>
                </p>
                <p class="customInfo">
                  {{ item.info }}
                </p>
              </el-col>
            </el-row>
          </a>
        </el-col>
      </el-row>
    </div>
    <!-- 流程部分 -->
    <div class="customList">
      <h1>极速棋牌定制服务流程</h1>
      <p style="color: #666666">
        极速棋牌拥有多年行业经验，能够帮助客户快速部署，二次开发棋牌项目，有效帮助客户降低成本投入，不论有无经验，无障碍对接，极速实现客户所想。
      </p>
      <el-row :gutter="20" class="hide">
        <el-col :span="5" :offset="0">
          <i class="el-icon-s-order"></i>
        </el-col>
        <el-col :span="5" :offset="0">
          <i class="el-icon-edit"></i>
        </el-col>
        <el-col :span="5" :offset="0">
          <i class="el-icon-s-claim"></i>
        </el-col>
        <el-col :span="5" :offset="0">
          <i class="el-icon-s-platform"></i>
        </el-col>
        <el-col :span="5" :offset="0">
          <i class="el-icon-document-checked"></i>
        </el-col>
      </el-row>
      <el-row gutter="20" class="hide">
        <el-col span="5">
          <h3>需求分析</h3>
          <p>提交后，客服会主动联系你，了解基本需求</p>
        </el-col>
        <el-col span="5">
          <h3>方案设计</h3>
          <p>极速与客户沟通细节，并提供定制方案</p></el-col
        >
        <el-col span="5">
          <h3>开发调试</h3>
          <p>确定方案与费用后，极速签订合同</p></el-col
        >
        <el-col span="5">
          <h3>需求分析</h3>
          <p>极速根据方案内容开发调试安装产品、调试系统</p></el-col
        >
        <el-col span="5">
          <h3>项目验收</h3>
          <p>开发完成后，配合你现场验收，确保所有功能符合要求</p></el-col
        >
      </el-row>
      <el-row :gutter="20" class="phoneList">
        <el-col :span="24" :offset="0">
          <i class="el-icon-s-order phoneListi"></i>
          <h3>需求分析</h3>
          <p>提交后，客服会主动联系你，了解基本需求</p>
        </el-col>
        <el-col :span="24" :offset="0">
          <i class="el-icon-edit phoneListi"></i>
          <h3>方案设计</h3>
          <p>极速与客户沟通细节，并提供定制方案</p>
        </el-col>
        <el-col :span="24" :offset="0">
          <i class="el-icon-s-claim phoneListi"></i>
          <h3>开发调试</h3>
          <p>确定方案与费用后，极速签订合同</p>
        </el-col>
        <el-col :span="24" :offset="0">
          <i class="el-icon-s-platform phoneListi"></i>
          <h3>需求分析</h3>
          <p>极速根据方案内容开发调试安装产品、调试系统</p>
        </el-col>
        <el-col :span="24" :offset="0">
          <i class="el-icon-document-checked phoneListi"></i>
          <h3>项目验收</h3>
          <p>开发完成后，配合你现场验收，确保所有功能符合要求</p>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      about: [
        {
          id: 1,
          image: "https://www.freeimg.cn/i/2024/08/13/66ba5359152cf.png",
          title: "游戏换皮服务",
          info: "不想和别人一样？有自己的独特审美？价格优惠，都可以定制游戏换皮服务。",
          //跳转链接
          url: "https://www.baidu.com",
        },
        {
          id: 2,
          image: "https://www.freeimg.cn/i/2024/08/13/66ba53591c750.png",
          title: "二次开发服务",
          info: "游戏功能满足不了现有需求？跟随需求，二次开发/修改更多功能。",

          //跳转链接
          url: "https://www.baidu.com",
        },
        {
          id: 3,
          image: "https://www.freeimg.cn/i/2024/08/13/66ba535915189.png",
          title: "外包维护包月服务",
          info: "无需招人就可以轻松解决开发/BUG修复问题，省时省心省力，还省下了高昂的技术人力成本和场地成本。",
          //跳转链接
          url: "https://www.baidu.com",
        },
        {
          id: 4,
          image: "https://www.freeimg.cn/i/2024/08/13/66ba53591cc3f.png",
          title: "个性化定制服务",
          info: "前面满足不了需求，与商务洽谈，制定更多个性化的服务。",
          //跳转链接
          url: "https://www.baidu.com",
        },
      ],
    };
  },
};
</script>

<style>
.buyBtn {
  font-size: 12px;
  color: #abb0bd;
  margin: 5px 0px;
  font-weight: bolder;
  border: 1px #abb0bd solid;
  padding: 5px 10px;
  border-radius: 5px;
  transition: all 0.3s ease;
}

.buyBtn:hover {
  background-color: #abb0bd;
  color: white;
}

.el-col-5 {
  width: 20%;
}

.el-col-5 > i {
  font-size: 40px;
  background-color: #ffa94f;
  color: white;
  padding: 20px;
  border-radius: 100px;
}

.el-col-5 > p {
  font-size: 13px;
}

.customTitle {
  color: #666666;
  padding: 10px 150px 0px 150px;
}

.customService {
  background-color: white;
  margin: 20px 150px 0 150px;
  padding: 15px;
  color: #666666;
}

.customInfo {
  color: #abb0bd;
  font-size: 12px;
  width: 150px;
  max-height: 3em;
  white-space: normal;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.customList {
  background-color: white;
  padding: 20px;
  margin: 20px 150px 0 150px;
  text-align: center;
}

.phoneList {
  display: none;
}

@media screen and (max-width: 768px) {
  .customTitle {
    display: none;
  }
  .customService {
    margin: 0;
  }
  .customInfo {
    width: 100%;
  }
  .customList {
    margin: 0;
  }
  .hide {
    display: none;
  }
  .phoneList {
    display: contents;
  }
  .phoneListi {
    font-size: 40px;
    background-color: #ffa94f;
    color: white;
    padding: 20px;
    border-radius: 100px;
  }
}
</style>
