<template>
  <div>
    <div class="Down_banner">
      <el-row :gutter="20">
        <el-col :span="12" :offset="0">
          <div class="left_col">
            <h1 class="banner_title1">下载极速棋牌APP</h1>
            <h1 class="banner_title2">
              您身边的棋牌游戏软件专家，只需APP在手，一键在线管理您的棋牌游戏
            </h1>
            <div style="margin-top: 20px; display: flex">
              <button
                class="downloadButton"
                style="display: flex"
                @click="iosToast = true"
              >
                <img
                  src="@/assets/down/apple-fill.png"
                  alt=""
                  style="height: 28px; width: 28px"
                />下载iOS版本
              </button>
              <a
                href="https://www.jisuqipai.online/Download/Jisuqipai.apk"
                target="_blank"
                style="text-decoration: none"
              >
                <button
                  class="downloadButton"
                  style="display: flex; margin-left: 30px"
                >
                  <img
                    src="@/assets/down/android.png"
                    alt=""
                    style="height: 28px; width: 28px"
                  />下载Android版本
                </button>
              </a>
            </div>
          </div>
        </el-col>
        <el-col :span="12" :offset="0" style="text-align: center">
          <img src="@/assets/down/phone.png" alt="" class="phoneImg" />
        </el-col>
      </el-row>
    </div>
    <!-- ios弹窗 -->
    <el-dialog
      title="提示"
      :visible.sync="iosToast"
      :width="dialogWidth"
      center
    >
      <span>极速棋牌APP暂不支持iOS</span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      iosToast: false,
      dialogWidth: "30%",
    };
  },
  methods: {
    screeneeWidth() {
      if (window.innerWidth < 768) {
        this.dialogWidth = "80%";
      } else {
        this.dialogWidth = "30%";
      }
    },
  },
  created() {
    this.screeneeWidth();
  },
};
</script>

<style>
.Down_banner {
  background-image: url("@/assets/down/banner.png");
  height: 400px;
  background-size: cover;
  overflow: hidden;
}

.downloadButton {
  font-size: 20px;
  background-color: initial;
  padding: 10px 20px;
  border-radius: 30px;
  color: white;
  border: 1px white solid;
  cursor: pointer;
  transition: all 0.3s ease;
}

.downloadButton:hover {
  background-color: #cb1414;
  border: 1px #cb1414 solid;
  color: white;
}

.banner_title1 {
  font-size: 50px;
  margin: 0;
}

.banner_title2 {
  margin: 0;
  font-weight: normal;
  color: #f8f8f8;
}

.left_col {
  color: white;
  text-align: left;
  padding: 110px 0 0 160px;
}

.phoneImg {
  width: 200px;
  height: 370px;
  margin-top: 30px;
}

@media screen and (max-width: 768px) {
  .Down_banner {
    height: 200px;
  }
  .banner_title1 {
    font-size: 20px;
  }
  .banner_title2 {
    font-size: 15px;
  }
  .left_col {
    padding: 20px 0 0 20px;
  }
  .downloadButton {
    font-size: 10px;
    padding: 5px;
    border-radius: 5px;
  }
  .phoneImg {
    height: 170px;
    width: 90px;
  }
}
</style>
