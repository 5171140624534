import Vue from "vue";
import Router from "vue-router";
import HomeVue from "@/components/HomeVue.vue";
import LoginVue from "@/components/LoginVue.vue";
import RegVue from "@/components/RegVue.vue";
import ServerVue from "@/components/ServerVue.vue";
import AboutVue from "@/components/AboutVue.vue";
import CustomService from "@/components/CustomService.vue";
import AgentVue from "@/components/AgentVue.vue";
import NewsDetail from "@/components/NewsDetail.vue";
import ServerDetail from "@/components/ServerDetail.vue";
import PayVue from "@/components/PayVue.vue";
import YonoVue from "@/components/YonoVue.vue";
import CokeVue from "@/components/CokeVue.vue";
import DownVue from "@/components/DownVue.vue";
import CreateVue from "@/components/CreateVue.vue";

Vue.use(Router);

export default new Router({
  routes: [
    { path: "/", component: HomeVue },
    { path: "/login", component: LoginVue },
    { path: "/reg", component: RegVue },
    { path: "/server", component: ServerVue },
    { path: "/about", component: AboutVue },
    { path: "/custom", component: CustomService },
    { path: "/agent", component: AgentVue },
    { path: "/news/:id", component: NewsDetail },
    { path: "/server/:id", component: ServerDetail },
    { path: "/pay", component: PayVue },
    { path: "/yono", component: YonoVue },
    { path: "/coke", component: CokeVue },
    { path: "/download", component: DownVue },
    { path: "/create", component: CreateVue },
  ],
});
