<template>
  <div>
    <div style="margin: 20px 150px">
      <!-- 套餐选择 -->
      <div style="display: flex">
        <div v-for="item in moneys" :key="item.id">
          <div
            class="moneyBtn"
            :class="{ active: money === item.money }"
            @click="money = item.money"
          >
            {{ item.money }}$
            <div v-if="money === item.money" class="triangle"></div>
          </div>
        </div>
      </div>
      <!-- 自定义输入 -->
      <div style="margin-top: 10px">
        <span style="color: #606266">其他金额：</span>
        <!-- <input
          type="text"
          style="
            background-color: transparent;
            border: 1px #606266 solid;
            border-radius: 2px;
          "
        /> -->
        <el-input-number
          v-model="money"
          controls-position="right"
          :min="0"
          :max="99999"
        ></el-input-number>
      </div>
      <!-- 充值方式 -->
      <div style="display: flex">
        <p style="color: #606266; margin-top: 30px">充值方式：</p>
        <el-radio-group v-model="radio">
          <el-radio :label="0"
            ><img
              src="@/assets/trc20.png"
              alt="trc20"
              style="
                height: 50px;
                width: 130px;
                transform: translateY(20px);
                border: 1px #909399 solid;
                border-radius: 2px;
              "
          /></el-radio>
        </el-radio-group>
      </div>
      <!-- 应付金额 -->
      <div style="margin-top: 30px">
        <span style="color: #606266">应付金额：</span>
        <span style="color: #cb1414; font-weight: bolder; font-size: 30px"
          >{{ money }} USDT</span
        >
      </div>
      <el-divider></el-divider>
      <!-- 确认支付 -->
      <button
        style="
          background-color: #cb1414;
          border-radius: 2px;
          color: white;
          border: 0;
          padding: 10px 20px;
        "
        @click="usdtPays()"
      >
        确认支付
      </button>
      <span style="font-size: 13px; color: #909399; margin-left: 20px"
        >温馨提示：充值USDT后可以直接在极速平台消费，系统有一定的延迟，充值成功后大约1-2分钟到账，充值遇到问题请及时与客服联系。</span
      >
    </div>
  </div>
</template>

<script>
import { usdtPay } from "@/api/api";
export default {
  data() {
    return {
      radio: 0,
      money: 50,
      moneys: [
        { id: 1, money: 50 },
        { id: 2, money: 100 },
        { id: 3, money: 200 },
        { id: 4, money: 500 },
        { id: 5, money: 1000 },
        { id: 6, money: 2000 },
        { id: 7, money: 5000 },
        { id: 8, money: 10000 },
        { id: 9, money: 20000 },
        { id: 10, money: 30000 },
      ],
    };
  },
  methods: {
    usdtPays() {
      if (this.money != 0 && this.money != null && this.money != "") {
        const loading = this.$loading({
          lock: true,
          text: "Loading",
          spinner: "el-icon-loading",
          background: "rgba(255, 255, 255, 0.7)",
        });
        const token = localStorage.getItem("token");
        usdtPay(this.money, token).then((res) => {
          if (res.data.code === 200) {
            this.$message.success("正在跳转");
            window.open(res.data.data.url);
            loading.close();
          } else {
            this.$message.error(res.data.msg);
            loading.close();
          }
        });
      } else {
        this.$message.error("请先选择或填写需要支付的金额！");
      }
    },
  },
  created() {},
};
</script>

<style>
.moneyBtn {
  display: inline-block;
  border: 1px #606266 solid;
  border-radius: 2px;
  padding: 5px 20px;
  color: #606266;
  margin-right: 20px;
  position: relative;
}

.moneyBtn.active {
  border: 1px #cb1414 solid;
  color: #cb1414;
}

.moneyBtn .triangle {
  width: 0;
  height: 0;
  border-left: 20px solid transparent;
  border-bottom: 15px solid #cb1414;
  position: absolute;
  bottom: 0;
  right: 0;
}

.el-radio__input.is-checked .el-radio__inner {
  border-color: #cb1414 !important;
  background: #cb1414 !important;
}
</style>
