<template>
  <div>
    <h1>ServerDetail{{ id }}</h1>
    <!-- 服务器部分 -->
    <div style="margin: 20px 150px 0 150px">
      <el-row style="border-bottom: 0.5px solid #666666">
        <el-col
          :span="6"
          :offset="0"
          style="background-color: #f8f8f8; padding: 20px"
        >
          <p style="margin: 0">香港二区</p>
          <p style="margin: 0; color: #666666; font-size: 13px">
            BGP国际高速宽带，免备案，低延时
          </p>
        </el-col>
        <el-col
          :span="9"
          :offset="0"
          style="padding: 20px; background-color: white"
        >
          <el-row style="text-align: center">
            <el-col :span="6" :offset="0">
              <p style="margin: 0">2核</p>
              <p style="margin: 0; color: #666666; font-size: 13px">CPU</p>
            </el-col>
            <el-col :span="6" :offset="0">
              <p style="margin: 0">2G</p>
              <p style="margin: 0; color: #666666; font-size: 13px">内存</p>
            </el-col>
            <el-col :span="6" :offset="0">
              <p style="margin: 0">2M</p>
              <p style="margin: 0; color: #666666; font-size: 13px">CN2带宽</p>
            </el-col>
            <el-col :span="6" :offset="0">
              <p style="margin: 0">50G</p>
              <p style="margin: 0; color: #666666; font-size: 13px">系统盘</p>
            </el-col>
          </el-row>
        </el-col>
        <el-col
          :span="9"
          :offset="0"
          style="background-color: white; padding: 8px"
        >
          <el-row>
            <el-col :span="8" :offset="0">
              <div
                style="
                  text-align: center;
                  color: #cb1414;
                  border: 1px #cb1414 solid;
                  display: inline-block;
                  padding: 5px 30px;
                  background-color: #ffefef;
                "
              >
                <p style="margin: 0; font-size: 13px">
                  <span style="font-size: 25px">29</span>元/月
                </p>
                <p style="margin: 0; font-size: 13px">立省76元</p>
              </div>
            </el-col>
            <el-col :span="9" :offset="0">
              <div
                style="
                  text-align: center;
                  color: #cb1414;
                  border: 1px #cb1414 solid;
                  display: inline-block;
                  padding: 5px 30px;
                "
              >
                <p style="margin: 0; font-size: 13px">
                  <span style="font-size: 25px">159</span>元/年
                </p>
                <p style="margin: 0; font-size: 13px">立省891元</p>
              </div>
            </el-col>
            <el-col :span="7" :offset="0">
              <button
                style="
                  background-color: #cb1414;
                  color: white;
                  border: 0px;
                  padding: 10px 20px;
                  margin-top: 13px;
                "
              >
                立即购买
              </button>
            </el-col>
          </el-row>
        </el-col>
      </el-row>
      <el-row>
        <el-col
          :span="6"
          :offset="0"
          style="background-color: #f8f8f8; padding: 20px"
        >
          <p style="margin: 0">香港二区</p>
          <p style="margin: 0; color: #666666; font-size: 13px">
            BGP国际高速宽带，免备案，低延时
          </p>
        </el-col>
        <el-col
          :span="9"
          :offset="0"
          style="padding: 20px; background-color: white"
        >
          <el-row style="text-align: center">
            <el-col :span="6" :offset="0">
              <p style="margin: 0">2核</p>
              <p style="margin: 0; color: #666666; font-size: 13px">CPU</p>
            </el-col>
            <el-col :span="6" :offset="0">
              <p style="margin: 0">2G</p>
              <p style="margin: 0; color: #666666; font-size: 13px">内存</p>
            </el-col>
            <el-col :span="6" :offset="0">
              <p style="margin: 0">2M</p>
              <p style="margin: 0; color: #666666; font-size: 13px">CN2带宽</p>
            </el-col>
            <el-col :span="6" :offset="0">
              <p style="margin: 0">50G</p>
              <p style="margin: 0; color: #666666; font-size: 13px">系统盘</p>
            </el-col>
          </el-row>
        </el-col>
        <el-col
          :span="9"
          :offset="0"
          style="background-color: white; padding: 8px"
        >
          <el-row>
            <el-col :span="8" :offset="0">
              <div
                style="
                  text-align: center;
                  color: #cb1414;
                  border: 1px #cb1414 solid;
                  display: inline-block;
                  padding: 5px 30px;
                  background-color: #ffefef;
                "
              >
                <p style="margin: 0; font-size: 13px">
                  <span style="font-size: 25px">29</span>元/月
                </p>
                <p style="margin: 0; font-size: 13px">立省76元</p>
              </div>
            </el-col>
            <el-col :span="9" :offset="0">
              <div
                style="
                  text-align: center;
                  color: #cb1414;
                  border: 1px #cb1414 solid;
                  display: inline-block;
                  padding: 5px 30px;
                "
              >
                <p style="margin: 0; font-size: 13px">
                  <span style="font-size: 25px">159</span>元/年
                </p>
                <p style="margin: 0; font-size: 13px">立省891元</p>
              </div>
            </el-col>
            <el-col :span="7" :offset="0">
              <button
                style="
                  background-color: #cb1414;
                  color: white;
                  border: 0px;
                  padding: 10px 20px;
                  margin-top: 13px;
                "
              >
                立即购买
              </button>
            </el-col>
          </el-row>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      id: this.$route.params.id,
    };
  },
};
</script>

<style></style>
